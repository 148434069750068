<template>
  <div class="bookmark" id="bookmark">

    <div class="header">
      <el-menu
          class="header-navigation"
          mode="horizontal"
          @select="handleSelect"
          background-color="rgb(84 92 100 / 0%)"
          text-color="#fff"
          active-text-color="#ffd04b">
        <el-menu-item index="1">
          <a href="/bookmark">
            <img class="logo-image"
                 src="http://api.admin.tqcd.vip/profile/upload/2022/01/13/21b95833-29da-4e68-81a2-3cceb58cdae4.png"/>
          </a>
        </el-menu-item>
        <el-menu-item index="2"><a href="http://tqcd.vip/" target="_blank">
          <div>我的首页</div>
        </a></el-menu-item>
        <el-submenu index="3">
          <template slot="title">我的工作台</template>
          <el-menu-item index="2-1">
            <a href="http://blog.tqcd.vip/" target="_blank">
              <div>个人博客</div>
            </a>
          </el-menu-item>
          <el-menu-item index="2-2">
            <a href="http://admin.tqcd.vip/" target="_blank">
              <div>后台管理</div>
            </a>
          </el-menu-item>
          <el-menu-item index="2-3">
            <a href="http://lanproxy.tqcd.vip/" target="_blank">
              <div>内网穿透</div>
            </a>
          </el-menu-item>
          <el-menu-item index="2-4">
            <a href="http://kodbox.tqcd.vip/" target="_blank">
              <div>私有云网盘</div>
            </a>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>

    <div class="search">
      <div>
        <el-row>
          <el-col :span="8">&nbsp;</el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <el-row>
                <el-col :span="12">
                  <div style="text-align: left">
                    <img height="110px"
                         src="http://api.admin.tqcd.vip/profile/upload/2022/04/07/1706f0dc-7458-44ca-b4c2-79bd0c1f92b6.png"/>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div style="text-align: right">
                    <img height="42px" style="position: absolute; bottom: 0; right: 0"
                         src="http://api.admin.tqcd.vip/profile/upload/2022/04/07/470da8ed-6cd6-425a-87a6-b8fd65cf9458.png"/>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="8">&nbsp;</el-col>
        </el-row>

      </div>
      <div class="search-form">
        <form target="_blank" action="http://www.baidu.com/s" method="GET"
              class="el-form demo-form-inline el-form--inline">
          <div class="el-form-item el-form-item--medium" style="width: 28%;">
            <div class="el-form-item__content" style="width: 100%;">
              <div class="el-input el-input--medium" style="width: 100%;">
                <input name="wd" type="text" autocomplete="off" class="el-input__inner">
              </div>
            </div>
          </div>
          <div class="el-form-item el-form-item--medium">
            <div class="el-form-item__content">
              <input type="submit" value="百度一下" class="el-button search-info el-button--primary el-button--medium">
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="bookmark-info">
      <template>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(item,index1) in aTagList.length" :key="index1" :label="aTagList[item-1].name" :name="item === 1 ?'first':aTagList[item-1].name">
          <div class="bookmark-name-info-one-all">
              <el-row>
                <div>
                  <ul class="bookmark-content" :style="{height: bookmarkContentHeight}">
                    <div v-if="aTagList[item-1].atagLists !== undefined && aTagList[item-1].atagLists.length >= 0">
                      <li v-for="(itemDetails,index2) in aTagList[item-1].atagLists.length" :key="index2">
                        <el-col :span="spanCount">
                          <div class="bookmark-name-info-one">
                            <a target="_blank" :href="aTagList[item-1].atagLists[itemDetails-1].url">
                              <el-avatar shape="square" size="small" :src="aTagList[item-1].atagLists[itemDetails-1].icoUrl"></el-avatar>
                              <span class="bookmark-name">{{ valueFormat(aTagList[item-1].atagLists[itemDetails-1].name, footBoolean ? 2 : 6) }}</span>
                            </a>
                          </div>
                        </el-col>
                      </li>
                    </div>
                  </ul>
                </div>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
      </template>
    </div>

    <MyWebFoot/>
  </div>
</template>
<script>
import MyWebFoot from '@/components/MyWebsite/MyWebFoot'

export default {
  name: "bookmark",
  components: {
    MyWebFoot
  },
  data() {
    return {
      activeName: 'first',
      tailInfo: ["Designed and developed by zhang ya long.", "冀ICP备19029577号", "Copyright © 2021-2022 tqcd.vip. All Rights Reserved."],
      footBoolean: document.body.clientWidth < 700,
      spanCount: document.body.clientWidth < 700 ? 12 : 4,
      bookmarkContentHeight: document.body.clientWidth < 700 ? '280px' : '380px',
      aTagList: [],
    }
  },
  mounted: function () {
    //获取标签信息
    this.$axios.post("/aTagList/findAllByATagList/0/2147483647", {"hideState": "0", "parentId": "0"}).then(response => {
      var result = response.data;
      if (200 === result.code) {
        this.aTagList = result.data.records;
      } else {
        this.$message.error("错误" + result);
      }
    }).catch(function (err) {
      this.$message.error("请求失败" + err);
    })
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    valueFormat(cellValue, length) {
      if (!cellValue) return ''
      if (cellValue.length > length) {
        return cellValue.slice(0, length) + '...'
      }
      return cellValue
    }
  },
  created() {
    setTimeout(() => {
      window.L2Dwidget.init({
        //男人
        // pluginModelPath: 'live2d-widget-model-chitose/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-chitose/assets/chitose.model.json' },
        //女孩
        // pluginModelPath: 'live2d-widget-model-epsilon2_1/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-epsilon2_1/assets/Epsilon2.1.model.json' },
        //彪悍男人
        // pluginModelPath: 'live2d-widget-model-gf/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-gf/assets/Gantzert_Felixander.model.json' },
        //女仆       7
        // pluginModelPath: 'live2d-widget-model-haru_1/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-haru_1/assets/haru01.model.json' },
        //女学生      8
        // pluginModelPath: 'live2d-widget-model-haru_2/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-haru_2/assets/haru02.model.json' },
        //小孩小学生男  7
        // pluginModelPath: 'live2d-widget-model-haruto/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-haruto/assets/haruto.model.json' },
        //小孩小学生女  7
        // pluginModelPath: 'live2d-widget-model-koharu/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-koharu/assets/koharu.model.json' },
        //高挑女学生    7
        // pluginModelPath: 'live2d-widget-model-hijiki/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-hibiki/assets/hibiki.model.json' },
        //小黑猫       6
        // pluginModelPath: 'live2d-widget-model-hijiki/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-hijiki/assets/hijiki.model.json' },
        //半身家庭女孩   5
        // pluginModelPath: 'live2d-widget-model-izumi/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-izumi/assets/izumi.model.json' },
        //初音未来      5
        pluginModelPath: 'live2d-widget-model-miku/assets/',
        model: { jsonPath: 'static/live2dw/live2d-widget-model-miku/assets/miku.model.json' },
        //科技小人物     4
        // pluginModelPath: 'live2d-widget-model-ni-j/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-ni-j/assets/ni-j.model.json' },
        //小动漫小人物短发1   3   9
        // pluginModelPath: 'live2d-widget-model-nico/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-nico/assets/nico.model.json' },
        //小动漫小人物长发1   3
        // pluginModelPath: 'live2d-widget-model-nietzsche/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-nietzsche/assets/nietzche.model.json' },
        //小动漫小人物长发2   3
        // pluginModelPath: 'live2d-widget-model-nipsilon/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-nipsilon/assets/nipsilon.model.json' },
        //小动漫小人物短发2   3
        // pluginModelPath: 'live2d-widget-model-nito/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-nito/assets/nito.model.json' },
        //看板娘       8
        // pluginModelPath: 'live2d-widget-model-shizuku/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-shizuku/assets/shizuku.model.json' },
        //小白猫       9     0
        // pluginModelPath: 'live2d-widget-model-tororo/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-tororo/assets/tororo.model.json' },
        //动漫高人物女   3    9
        // pluginModelPath: 'live2d-widget-model-tsumiki/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-tsumiki/assets/tsumiki.model.json' },
        //小黄蜂女      3   9
        // pluginModelPath: 'live2d-widget-model-unitychan/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-unitychan/assets/unitychan.model.json' },
        //碗里狗       6
        // pluginModelPath: 'live2d-widget-model-wanko/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-wanko/assets/wanko.model.json' },
        //护士女       7
        // pluginModelPath: 'live2d-widget-model-z16/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-z16/assets/z16.model.json' },

        pluginRootPath: 'static/live2dw/',
        pluginJsPath: 'lib/',
        tagMode: false,
        debug: false,
        display: { position: 'right', width: 150, height: 300 },
        mobile: { show: true },
        log: false
      })
    }, 1000)
  },
}
</script>
<style lang="scss">
.bookmark {
  height: 100%;
  background-image: url("http://api.admin.tqcd.vip/profile/upload/2022/01/13/ebf9b1ff-9eb7-4b18-a586-2986249a9a47.webp");
  background-size: 100% 100%;

  .header {
    color: white;

    .el-menu.el-menu--horizontal {
      border-bottom: solid 0px #e6e6e6;
    }

    .logo-image {
      width: 90px;
    }
  }

  .search {
    text-align: center;
    margin-top: 6%;

    .search-info {
      width: 100px;
    }
  }

  .bookmark-info {
    background-color: #ffffffc9;
    margin: 2% 16% 2% 16%;
    padding: 1% 2% 1% 2%;

    .bookmark-name-info-one-all {
      border: 1px solid #ccc;
      border-radius: 16px;
      padding: 0 15px 0 15px;

      .bookmark-name-info-one {
        position: relative;
        padding: 6px;

        .bookmark-name {
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          top: 19%;
          left: 42px;
        }
      }

      .bookmark-content {
        /*设置在竖直方向上可滚动*/
        overflow-y: scroll;
        /*overflow:hidden样式可以不写，因为scroll会自动隐藏超出content高度的部分*/
        li {
          list-style: none;
        }

        padding-left: 0;
      }
    }
  }
}
</style>
