<template>
  <div id="MyWebFoot">
    <div class="foot">
      <div>
        <div v-if="footBoolean">
          <a href="https://beian.miit.gov.cn">
            {{ tailInfo[1] }}
          </a>
          <br>
          {{ tailInfo[0] }}
          <br>
          {{ tailInfo[2] }}
        </div>
        <div v-else>
          <span>{{ tailInfo[0] }}</span>
          <span><a href="https://beian.miit.gov.cn" target="_blank">{{ tailInfo[1] }}</a></span>
          <span>{{ tailInfo[2] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyWebFoot",
  data() {
    return {
      tailInfo: ["Designed and developed by zhang ya long.", "冀ICP备19029577号", "Copyright © 2021-2022 tqcd.vip. All Rights Reserved."],
      footBoolean: document.body.clientWidth < 700,
    }
  }
}
</script>

<style lang="scss">
#MyWebFoot {
  .foot {
    background-color: #2b2f3a;
    color: #c0d2d2;
    text-align: center;
    padding: 3px;
    width: 100%;
    position: fixed;
    bottom: 0;

    span {
      padding: 0 10px 0 10px;
    }
  }
}
</style>
